import React, { useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { HashLink } from "react-router-hash-link";
import { Collapse } from "bootstrap/dist/js/bootstrap.bundle.min";

const Home = () => {
  const navbarCollapseRef = useRef(null);

  const toggleNavbar = () => {
    const navbar = document.getElementById("navbarNav");
    if (navbar) {
      const bsCollapse = new Collapse(navbar, {
        toggle: false,
      });
      bsCollapse.hide();
    }
  };

  const handleSetActiveLink = (link) => {
    toggleNavbar();
  };

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent);
  };

  // if (isMobileDevice()) {
  //   console.log("This is a mobile device.");
  // } else {
  //   console.log("This is a desktop device.");
  // }

  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src = "js/click-scroll.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      <main>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <div className="d-flex align-items-center">
              <a className="navbar-brand" href="/">
                <img src="img/logo.png" alt="ICB Verse" width={140} />
              </a>
            </div>
            <a
              href={
                isMobileDevice()
                  ? "https://app.icbverse.io/"
                  : "https://web.icbverse.io/"
              }
              className="btn custom-btn d-lg-none ms-auto me-4"
            >
              Demo
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse "
              id="navbarNav"
              ref={navbarCollapseRef}
            >
              <ul className="navbar-nav mx-auto align-items-lg-center">
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#home")}
                >
                  <HashLink
                    smooth
                    to="/#home"
                    className="nav-link click-scroll"
                  >
                    Home
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#about")}
                >
                  <HashLink
                    smooth
                    to="/#about"
                    className="nav-link click-scroll"
                  >
                    About
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#roadmap")}
                >
                  <HashLink
                    smooth
                    to="/#roadmap"
                    className="nav-link click-scroll"
                  >
                    Roadmap
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#utility")}
                >
                  <HashLink
                    smooth
                    to="/#utility"
                    className="nav-link click-scroll"
                  >
                    Utility
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#buynft")}
                >
                  <HashLink
                    smooth
                    to="/#buynft"
                    className="nav-link click-scroll"
                  >
                    Buy Nft
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/contact")}
                >
                  <HashLink
                    smooth
                    to="/contact"
                    className="nav-link click-scroll"
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>

              <div className="d-flex align-items-center justify-content-end">
                <a
                  href={
                    isMobileDevice()
                      ? "https://app.icbverse.io/"
                      : "https://web.icbverse.io/"
                  }
                  className="btn custom-btn d-lg-block d-none"
                >
                  Demo
                </a>
                <a href="/" className="btn btn-login  d-lg-block d-none ms-2">
                  Login
                </a>
              </div>

              <div className="d-block d-lg-none  ">
                <a href="/" className="btn btn-login">
                  Login
                </a>
              </div>
            </div>
          </div>
        </nav>

        <section className="hero-section" id="home">
          <div className="section-overlay"></div>

          <div className="container d-flex justify-content-center align-items-center">
            <div className="row">
              <div className="col-12 mt-auto mb-2 text-center">
                <h1 className="text-white mb-1">Metaverse Educational City</h1>
                <p className="hero-desc">
                  Dive into our state-of-the-art virtual classrooms, interactive
                  labs, and expansive digital libraries. Engage with immersive
                  simulations and real-time collaborative projects that bring
                  education to life.
                </p>
                <a className="btn custom-btn smoothscroll" href="#about">
                  View Demo
                </a>
              </div>
            </div>
          </div>

          <div className="video-wrap">
            <video autoPlay loop muted playsInline className="custom-video">
              <source src="video/icb-banner.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>

        <section className="about-section section-padding" id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 mb-4 mb-lg-0 d-flex align-items-center order-md-1 order-2 ">
                <div className="services-info">
                  <img
                    src="img/Welcome.png"
                    alt="about us"
                    className="img-fluid icon-shape"
                  />

                  <h2 className="text-white mb-4">
                    Welcome to ICB Verse: The Future of Learning in the
                    Metaverse
                  </h2>

                  <p className="text-white mb-5">
                    Step into ICB Verse, a pioneering Metaverse city dedicated
                    to advancing education and knowledge. Experience a new era
                    of learning where you can attend classes by world-renowned
                    professors, converse with AI clones of legendary figures in
                    the Fame Hall, and impart your own skills in a vibrant
                    virtual community. With ICB Verse, education becomes an
                    engaging and interactive adventure. Secure your access
                    through unique NFTs and embark on a transformative journey
                    that bridges the gap between traditional learning and
                    futuristic innovation. Join us in reshaping the educational
                    landscape.
                  </p>
                  <a
                    href="https://web.icbverse.io/"
                    className="btn custom-btn  "
                  >
                    Demo
                  </a>
                  <a href="/" className="btn btn-login ms-2">
                    Login
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-12 order-md-2 order-1">
                <div className="about-text-wrap">
                  <img
                    src="img/land.png"
                    alt="About"
                    className="about-image img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="roadmap-section section-padding" id="roadmap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12 mx-auto ">
                <div className="d-flex flex-column align-items-center text-center mb-4">
                  {/* Icon Image */}
                  <img
                    src="img/Roadmap.png"
                    className="img-fluid icon-shape-roadmap mb-3"
                    alt="Roadmap Icon"
                  />

                  {/* Title */}
                  <h2 className="text-white">Road Map</h2>
                </div>
                <nav className="d-flex justify-content-center">
                  <div
                    className="nav nav-tabs align-items-baseline justify-content-center"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className="nav-link active"
                      id="nav-ContactForm-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-ContactForm"
                      type="button"
                      role="tab"
                      aria-controls="nav-ContactForm"
                      aria-selected="false"
                    >
                      <h5>2024</h5>
                    </button>

                    <button
                      className="nav-link"
                      id="nav-ContactMap-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-ContactMap"
                      type="button"
                      role="tab"
                      aria-controls="nav-ContactMap"
                      aria-selected="false"
                    >
                      <h5>2025</h5>
                    </button>
                  </div>
                </nav>

                <div className="tab-content shadow-lg mt-5" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-ContactForm"
                    role="tabpanel"
                    aria-labelledby="nav-ContactForm-tab"
                  >
                    <img
                      src="img/roadmap/2024.png"
                      alt=""
                      className="roadmap-image d-lg-block d-none"
                    />
                    <div className="d-lg-none d-block">
                      <VerticalTimeline>
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          contentStyle={{
                            background: "rgba(0, 31, 64, 1)",
                            color: "#fff",
                          }}
                          icon={
                            <img
                              src="img/roadmap/icon1-24.png"
                              alt="Custom Icon"
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                        >
                          <strong className="vertical-timeline-element-title">
                            Planning and Team Building
                          </strong>

                          <div className="list-style">
                            <div className="col-lg-12">
                              <ul className="roadmap-bullet">
                                <li>
                                  <p className="ms-1">
                                    {" "}
                                    Finalize ICB Verse concept and vision
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1"> Assemble core team</p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    {" "}
                                    Conduct market research and user surveys
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    {" "}
                                    Survey potential partnerships with
                                    educational institutions{" "}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          contentStyle={{
                            background: "rgba(0, 31, 64, 1)",
                            color: "#fff",
                          }}
                          icon={
                            <img
                              src="img/roadmap/icon2-24.png"
                              alt="Custom Icon"
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                        >
                          <strong className="vertical-timeline-element-title">
                            Preparation and Project Draft
                          </strong>

                          <div className="list-style">
                            <div className="col-lg-12">
                              <ul className="roadmap-bullet">
                                <li>
                                  <p className="ms-1">
                                    Finalize team and platform design
                                  </p>{" "}
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Develop and test The Universities
                                    Neighborhood
                                  </p>{" "}
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Prepare marketing materials and finalize
                                    NFTs
                                  </p>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          contentStyle={{
                            background: "rgba(0, 31, 64, 1)",
                            color: "#fff",
                          }}
                          icon={
                            <img
                              src="img/roadmap/icon3-24.png"
                              alt="Custom Icon"
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                        >
                          <strong className="vertical-timeline-element-title">
                            The Universities Neighborhood
                          </strong>

                          <div className="list-style">
                            <div className="col-lg-12">
                              <ul className="roadmap-bullet">
                                <li>
                                  <p className="ms-1"> Launch website</p>{" "}
                                </li>
                                <li>
                                  <p className="ms-1">
                                    {" "}
                                    Release private sales NFTs for classroom
                                    access
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    {" "}
                                    Launch private beta and gather feedback
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    {" "}
                                    Airdrop for private NFT holders
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                          className="vertical-timeline-element--education"
                          contentStyle={{
                            background: "rgba(0, 31, 64, 1)",
                            color: "#fff",
                          }}
                          icon={
                            <img
                              src="img/roadmap/icon4-24.png"
                              alt="Custom Icon"
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                        >
                          <strong className="vertical-timeline-element-title">
                            Expansion and Interaction
                          </strong>

                          <div className="list-style">
                            <div className="col-lg-12">
                              <ul className="roadmap-bullet">
                                <li>
                                  <p className="ms-1">
                                    Expand university facilities
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Enhance interactive tools
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    {" "}
                                    Host community events and live Q&A sessions
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    {" "}
                                    Release public sales NFTs for Knowledge
                                    Village
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Launch Knowledge Village platform
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Airdrop for NFT holders
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </VerticalTimelineElement>
                      </VerticalTimeline>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="nav-ContactMap"
                    role="tabpanel"
                    aria-labelledby="nav-ContactMap-tab"
                  >
                    <img
                      src="img/roadmap/2025.png"
                      alt=""
                      className="roadmap-image d-lg-block d-none"
                    />
                    <div className="d-lg-none d-block">
                      <VerticalTimeline>
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          contentStyle={{
                            background: "rgba(0, 31, 64, 1)",
                            color: "#fff",
                          }}
                          icon={
                            <img
                              src="img/roadmap/icon1-25.png"
                              alt="Custom Icon"
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                        >
                          <strong className="vertical-timeline-element-title">
                            The Fame Hall
                          </strong>

                          <div className="list-style">
                            <div className="col-lg-12">
                              <ul className="roadmap-bullet">
                                <li>
                                  <p className="ms-1">
                                    Launch AI clones of renowned figures{" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Develop interactive interfaces for AI
                                    conversations{" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Release private sales NFTs for Fame Hall
                                    access{" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Launch private beta and gather feedback{" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Airdrop for NFT holders{" "}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          contentStyle={{
                            background: "rgba(0, 31, 64, 1)",
                            color: "#fff",
                          }}
                          icon={
                            <img
                              src="img/roadmap/icon2-25.png"
                              alt="Custom Icon"
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                        >
                          <strong className="vertical-timeline-element-title">
                            AI Clone Expansion
                          </strong>

                          <div className="list-style">
                            <div className="col-lg-12">
                              <ul className="roadmap-bullet">
                                <li>
                                  {" "}
                                  <p className="ms-1">
                                    Expand roster of AI clones{" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Introduce themed events and workshops{" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Release public sales NFTs for Fame Hall{" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Launch Fame Hall platform{" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Airdrop for NFT holders{" "}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          contentStyle={{
                            background: "rgba(0, 31, 64, 1)",
                            color: "#fff",
                          }}
                          icon={
                            <img
                              src="img/roadmap/icon3-25.png"
                              alt="Custom Icon"
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                        >
                          <strong className="vertical-timeline-element-title">
                            The ICB Land
                          </strong>

                          <div className="list-style">
                            <div className="col-lg-12">
                              <ul className="roadmap-bullet">
                                <li>
                                  <p className="ms-1">Launch website </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Exciting developments to be announced!
                                  </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    Stay tuned for groundbreaking features and
                                    opportunities.{" "}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                          className="vertical-timeline-element--education"
                          contentStyle={{
                            background: "rgba(0, 31, 64, 1)",
                            color: "#fff",
                          }}
                          icon={
                            <img
                              src="img/roadmap/icon4-25.png"
                              alt="Custom Icon"
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                        >
                          <strong className="vertical-timeline-element-title">
                            Community and Monetization
                          </strong>

                          <div className="list-style">
                            <div className="col-lg-12">
                              <ul className="roadmap-bullet">
                                <li>
                                  <p className="ms-1">Surprises ahead! </p>
                                </li>
                                <li>
                                  <p className="ms-1">
                                    {" "}
                                    Look out for innovative community features
                                    and monetization options.{" "}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </VerticalTimelineElement>
                      </VerticalTimeline>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="artists-section section-padding" id="utility">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 mb-4 mb-lg-0 d-flex align-items-center">
                <div className="services-info">
                  <img
                    src="img/Unlock.png"
                    className="img-fluid icon-shape"
                    alt=""
                  />

                  <h2 className="text-white mb-4">
                    Unlock Exclusive Utilities and Opportunities with ICB Verse
                    NFTs
                  </h2>

                  <p className="text-white">
                    ICB Verse NFTs are more than just digital collectibles—they
                    are your gateway to a transformative educational experience.
                    Each NFT serves as an access token, offering unique benefits
                    and exclusive content across our Metaverse. Whether you're
                    joining prestigious universities, interacting with legendary
                    figures, or creating and teaching in virtual classrooms,
                    your NFTs provide the keys to unlock a world of learning and
                    engagement.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 col-12 mt-4">
                <div className="row text-center">
                  <div className="col-lg-6 mb-3 d-flex align-items-stretch">
                    <div className="card">
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title mb-3">
                          Exclusive Access to Premier Institutions{" "}
                        </h5>
                        <p className="card-text">
                          Unlock virtual classrooms and exclusive courses from
                          top-rated professors worldwide.{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-3 d-flex align-items-stretch">
                    <div className="card">
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title mb-3">
                          Airdrops and Rewards
                        </h5>
                        <p className="card-text">
                          Enjoy periodic airdrops and exclusive rewards for NFT
                          holders, enhancing your engagement and experience in
                          the Metaverse.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-3 d-flex align-items-stretch">
                    <div className="card">
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title mb-3">
                          Engage with AI Legends
                        </h5>
                        <p className="card-text">
                          {" "}
                          Enter The Fame Hall and interact with AI clones of
                          historic and contemporary icons, gaining insights and
                          knowledge from the greatest minds.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3 d-flex align-items-stretch">
                    <div className="card">
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title mb-3">
                          Invitation to VIP Virtual Events
                        </h5>
                        <p className="card-text">
                          {" "}
                          Gain access to exclusive virtual events, workshops,
                          and networking opportunities reserved for NFT holders.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="schedule-section" id="buynft">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 mb-4 mb-lg-0 d-flex align-items-center  order-md-1 order-2">
                <div className="services-info">
                  <img
                    src="img/NFT.png"
                    className="img-fluid icon-shape"
                    alt=""
                  />

                  <h2 className="text-white mb-4">
                    Secure Your Spot in the Metaverse: Purchase Your ICB Verse
                    NFTs Now!
                  </h2>
                  <p className="text-white">
                    Don’t miss out on your chance to be part of the
                    revolutionary ICB Verse. Our NFTs are more than just digital
                    tokens—they’re your gateway to exclusive educational
                    experiences, legendary AI interactions, and customizable
                    teaching spaces. Click below to purchase your NFTs and
                    unlock a world of opportunities that will transform how you
                    learn, teach, and invest. Be among the first to gain access
                    to groundbreaking content and premium rewards. Step into the
                    future of education and investment with ICB Verse!
                  </p>
                  <a
                    href="https://web.icbverse.io/"
                    className="btn custom-btn  "
                  >
                    Buy NFT
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12 order-md-2 order-1">
                <img src="img/buynft.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="pricing-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12 mx-auto">
                <img
                  src="img/FAQ.png"
                  className="img-fluid icon-shape"
                  style={{ width: "7%" }}
                  alt=""
                />
                <h2 className="text-white mb-4">Frequently Asked Questions</h2>
              </div>

              <div className="col-lg-12 col-12 mx-auto">
                <div
                  className="accordion accordion-flush"
                  id="accordionExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coll1"
                        aria-expanded="true"
                        aria-controls="coll1"
                      >
                        <strong> What is ICB Verse?</strong>
                      </button>
                    </h2>
                    <div
                      id="coll1"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        ICB Verse is a pioneering Metaverse city focused on
                        transforming education. It offers immersive learning
                        experiences, including access to top-rated professors,
                        interactive AI clones of historical figures, and
                        customizable virtual classrooms.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coll2"
                        aria-expanded="false"
                        aria-controls="coll2"
                      >
                        <strong> How do I use ICB Verse NFTs?</strong>
                      </button>
                    </h2>
                    <div
                      id="coll2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        ICB Verse NFTs act as access tokens. They grant you
                        entry to various features within the Metaverse, such as
                        virtual classrooms in The Universities Neighborhood,
                        interactive sessions with AI clones in The Fame Hall,
                        and custom classroom creation in The ICB Land.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coll3"
                        aria-expanded="false"
                        aria-controls="coll3"
                      >
                        <strong> How can I purchase ICB Verse NFTs?</strong>
                      </button>
                    </h2>
                    <div
                      id="coll3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        You can buy ICB Verse NFTs directly through our
                        marketplace. Click the "Buy NFTs" button on our website
                        to view available options and make your purchase.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coll4"
                        aria-expanded="false"
                        aria-controls="coll4"
                      >
                        <strong>What benefits do NFT holders receive?</strong>
                      </button>
                    </h2>
                    <div
                      id="coll4"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        NFT holders enjoy exclusive access to elite educational
                        content, interactive AI experiences, premium airdrops,
                        and invitations to special events. NFTs also provide
                        investment value through appreciation and secondary
                        market trading.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coll5"
                        aria-expanded="false"
                        aria-controls="coll5"
                      >
                        <strong> How often do airdrops occur?</strong>
                      </button>
                    </h2>
                    <div
                      id="coll5"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Airdrops are typically scheduled around key milestones
                        and NFT releases. Stay updated through our announcements
                        and newsletters to ensure you don’t miss out on any
                        rewards.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coll6"
                        aria-expanded="false"
                        aria-controls="coll6"
                      >
                        <strong>
                          {" "}
                          Can I join the platform without an NFT?
                        </strong>
                      </button>
                    </h2>
                    <div
                      id="coll6"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        You can access a demo version of the platform as a trial
                        to explore basic features. However, to fully experience
                        all functionalities and exclusive content, including
                        premium educational courses and interactive sessions,
                        you will need to purchase the appropriate NFTs.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coll7"
                        aria-expanded="false"
                        aria-controls="coll7"
                      >
                        <strong>
                          {" "}
                          Do I need a MetaMask wallet to enter ICB Verse?
                        </strong>
                      </button>
                    </h2>
                    <div
                      id="coll7"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Yes, you need a MetaMask wallet to buy NFTs and log into
                        the ICB Verse platform. Your MetaMask wallet will be
                        used for authentication with your purchased NFTs,
                        ensuring secure access to all features and content.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coll8"
                        aria-expanded="false"
                        aria-controls="coll8"
                      >
                        <strong>
                          Who can I contact for support or further questions?
                        </strong>
                      </button>
                    </h2>
                    <div
                      id="coll8"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        For any support or additional questions, please visit
                        our "Contact" page or email us at support@icbverse.com.
                        Our team is here to assist you with any inquiries.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="main-footer">
        <div className="site-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12 text-center">
                <ul className="site-footer-links">
                  <li className="site-footer-link-item">
                    {/* <a href="/#home" className="site-footer-link">
                      Home
                    </a> */}
                    <HashLink smooth to="/#home" className="site-footer-link">
                      Home
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/#about" className="site-footer-link">
                      About
                    </a> */}
                    <HashLink smooth to="/#about" className="site-footer-link">
                      About
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/#roadmap" className="site-footer-link">
                      Roadmap
                    </a> */}
                    <HashLink
                      smooth
                      to="/#roadmap"
                      className="site-footer-link"
                    >
                      Roadmap
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/#utility" className="site-footer-link">
                      Utility
                    </a> */}
                    <HashLink
                      smooth
                      to="/#utility"
                      className="site-footer-link"
                    >
                      Utility
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/#buynft" className="site-footer-link">
                      Buy NFT
                    </a> */}
                    <HashLink smooth to="/#buynft" className="site-footer-link">
                      Buy NFT
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/contact" className="site-footer-link">
                      Contact
                    </a> */}
                    <HashLink smooth to="/contact" className="site-footer-link">
                      Contact
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="site-footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-12 mt-1 text-center">
                  <p className="copyright-text">
                    Copyright © 2024 ICB Verse. All rights reserved
                  </p>
                </div>

                <div className="col-lg-12 col-12  justify-content-lg-center align-items-center">
                  <ul className="social-icon justify-content-lg-center align-items-center">
                    <li className="social-icon-item">
                      <a
                        href="https://t.me/icbnetwork_official"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon-link"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.75 0.25C12.0312 0.25 15.5 3.71875 15.5 8C15.5 12.2812 12.0312 15.75 7.75 15.75C3.46875 15.75 0 12.2812 0 8C0 3.71875 3.46875 0.25 7.75 0.25ZM11.5312 5.5625C11.6562 5.0625 11.3438 4.84375 11 4.96875L3.53125 7.84375C3.03125 8.03125 3.03125 8.34375 3.4375 8.46875L5.34375 9.0625L9.78125 6.25C10 6.125 10.1875 6.21875 10.0312 6.34375L6.4375 9.59375L6.3125 11.5625C6.5 11.5625 6.59375 11.4688 6.6875 11.375L7.625 10.4688L9.5625 11.9062C9.9375 12.0938 10.1875 12 10.2812 11.5625L11.5312 5.5625Z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </a>
                    </li>

                    <li className="social-icon-item">
                      <a
                        href="https://twitter.com/icbx_network"
                        className="social-icon-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M9.29401 6.928L14.357 1H13.157L8.76201 6.147L5.25001 1H1.20001L6.51001 8.784L1.20001 15H2.40001L7.04201 9.564L10.751 15H14.801L9.29401 6.928ZM7.65101 8.852L7.11301 8.077L2.83201 1.91H4.67501L8.12901 6.887L8.66701 7.662L13.158 14.132H11.315L7.65101 8.852Z"
                            fill="white"
                          ></path>
                        </svg>
                      </a>
                    </li>

                    <li className="social-icon-item">
                      <a
                        href="https://discord.com/invite/baGw2Kkz"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon-link"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M9.28125 7.625C9.28125 7.125 8.90625 6.71875 8.46875 6.71875C8 6.71875 7.625 7.125 7.625 7.625C7.625 8.09375 8 8.5 8.46875 8.5C8.90625 8.5 9.28125 8.09375 9.28125 7.625ZM5.53125 6.71875C5.09375 6.71875 4.71875 7.125 4.71875 7.625C4.71875 8.09375 5.09375 8.5 5.53125 8.5C6 8.5 6.34375 8.09375 6.34375 7.625C6.375 7.125 6 6.71875 5.53125 6.71875ZM14 1.65625C14 0.75 13.25 0 12.3438 0H1.625C0.71875 0 0 0.75 0 1.65625V12.4688C0 13.4062 0.71875 14.125 1.625 14.125H10.6875L10.2812 12.6562C12.625 14.8125 11.9688 14.25 14 16V1.65625ZM11.7188 9.25C11.7188 9.25 11.0312 10.4062 9.25 10.4688C9.25 10.4688 8.96875 10.125 8.71875 9.8125C9.78125 9.53125 10.1875 8.875 10.1875 8.875C9.84375 9.09375 9.53125 9.21875 9.25 9.34375C7.53125 10.0625 5.625 9.8125 4.25 9.0625C4.25 9.03125 4.0625 8.9375 3.9375 8.84375C3.9375 8.84375 4.3125 9.5 5.34375 9.8125C5.09375 10.0938 4.8125 10.4688 4.8125 10.4688C3.03125 10.4062 2.375 9.25 2.375 9.25C2.375 6.65625 3.5 4.59375 3.5 4.59375C4.65625 3.71875 5.75 3.75 5.75 3.75L5.84375 3.84375C4.40625 4.25 3.75 4.875 3.75 4.875C3.75 4.875 3.90625 4.78125 4.21875 4.65625C6.0625 3.84375 8.28125 3.84375 10.1875 4.875C10.1875 4.875 9.5625 4.28125 8.1875 3.875L8.3125 3.75C8.3125 3.75 9.40625 3.71875 10.5625 4.59375C10.5625 4.59375 11.7188 6.65625 11.7188 9.25Z"
                            fill="#F3F3F3"
                          ></path>
                        </svg>
                      </a>
                    </li>

                    <li className="social-icon-item">
                      <a
                        href="https://readicbnetwork.medium.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon-link"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_2)">
                            <path
                              d="M4.5 4.5L7.5 8.5L10.5 4.5M4.5 4.5H3M4.5 4.5V11M10.5 4.5H12M10.5 4.5V11M3 10.5H6M9 10.5H12M1.5 0.5H13.5C13.7652 0.5 14.0196 0.605357 14.2071 0.792893C14.3946 0.98043 14.5 1.23478 14.5 1.5V13.5C14.5 13.7652 14.3946 14.0196 14.2071 14.2071C14.0196 14.3946 13.7652 14.5 13.5 14.5H1.5C1.23478 14.5 0.98043 14.3946 0.792893 14.2071C0.605357 14.0196 0.5 13.7652 0.5 13.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5Z"
                              stroke="white"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1_2">
                              <rect width="15" height="15" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Home;
