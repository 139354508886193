import React, { useState, useEffect, useRef } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { HashLink } from "react-router-hash-link";
import { Collapse } from "bootstrap/dist/js/bootstrap.bundle.min";
import toast, { Toaster } from "react-hot-toast";
const Contact = () => {
  const [phone, setPhone] = useState("");

  const handleSubmit = async (e) => {
    console.log(phone);
    e.preventDefault();
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    const formattedPhone = phone.replace(/\+| /g, "");
    formDatab.append("Phone", formattedPhone);
    console.log(formattedPhone);

    // console.log(formDatab);
    fetch(
      "https://script.google.com/macros/s/AKfycbxtKYwFj2y_vQnn5WA4O24GiKQE7YM1j1IQz0rYSsCwTuQUUvP3YT-ComMmby2ZkABgWw/exec",
      {
        method: "POST",
        mode: "no-cors",
        body: formDatab,
      }
    )
      .then(() => {
        toast.success(
          "Thank you. The requested information has been submitted successfully."
        );
        formEle.reset();
        setPhone("");
      })

      .catch((error) => {
        console.error("Error:", error);
        toast.error("Somethings went wrong, please try again.");
      });
  };

  const navbarCollapseRef = useRef(null);

  const toggleNavbar = () => {
    const navbar = document.getElementById("navbarNav");
    if (navbar) {
      const bsCollapse = new Collapse(navbar, {
        toggle: false,
      });
      bsCollapse.hide();
    }
  };

  const handleSetActiveLink = (link) => {
    toggleNavbar();
  };

  useEffect(() => {
    const script = document.querySelector('script[src="js/click-scroll.js"]');
    if (script) {
      script.remove();
    }
  }, []);
  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent);
  };
  return (
    <>
      <main>
        <nav className="navbar navbar-expand-lg contact-nav">
          <div className="container">
            {/* Logo aligned to the left */}
            <div className="d-flex align-items-center">
              <a className="navbar-brand" href="/">
                <img src="img/logo.png" alt="ICB Verse" width={140} />
              </a>
            </div>
            <a
              href={
                isMobileDevice()
                  ? "https://app.icbverse.io/"
                  : "https://web.icbverse.io/"
              }
              className="btn custom-btn d-lg-none ms-auto me-4"
            >
              Demo
            </a>

            {/* Navbar toggler for mobile view */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* Navbar items centered */}
            <div
              className="collapse navbar-collapse"
              id="navbarNav"
              ref={navbarCollapseRef}
            >
              <ul className="navbar-nav mx-auto align-items-lg-center">
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#home")}
                >
                  {/* <a className="nav-link click-scroll" href="#home">
                    Home
                  </a> */}
                  <HashLink
                    smooth
                    to="/#home"
                    className="nav-link click-scroll"
                  >
                    Home
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#about")}
                >
                  {/* <a className="nav-link click-scroll" href="#about">
                    About
                  </a> */}
                  <HashLink
                    smooth
                    to="/#about"
                    className="nav-link click-scroll"
                  >
                    About
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#roadmap")}
                >
                  {/* <a className="nav-link click-scroll" href="#roadmap">
                    Roadmap
                  </a> */}
                  <HashLink
                    smooth
                    to="/#roadmap"
                    className="nav-link click-scroll"
                  >
                    Roadmap
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#utility")}
                >
                  {/* <a className="nav-link click-scroll" href="#utility">
                    Utility
                  </a> */}
                  <HashLink
                    smooth
                    to="/#utility"
                    className="nav-link click-scroll"
                  >
                    Utility
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/#buynft")}
                >
                  {/* <a className="nav-link click-scroll" href="#buynft">
                    Buy Nft
                  </a> */}
                  <HashLink
                    smooth
                    to="/#buynft"
                    className="nav-link click-scroll"
                  >
                    Buy Nft
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleSetActiveLink("/contact")}
                >
                  {/* <a className="nav-link click-scroll" href="/contact">
                    Contact
                  </a> */}
                  <HashLink
                    smooth
                    to="/contact"
                    className="nav-link click-scroll"
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>
              {/* Buttons aligned to the right */}
              <div className="d-flex align-items-center justify-content-end">
                <a
                  href={
                    isMobileDevice()
                      ? "https://app.icbverse.io/"
                      : "https://web.icbverse.io/"
                  }
                  className="btn custom-btn d-lg-block d-none"
                >
                  Demo
                </a>
                <a href="/" className="btn btn-login  d-lg-block d-none ms-2">
                  Login
                </a>
              </div>

              <div className="d-block d-lg-none  ">
                <a href="/" className="btn btn-login">
                  Login
                </a>
              </div>
            </div>
          </div>
        </nav>
        <Toaster></Toaster>
        <section className="contact-section ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12 mx-auto mt-4">
                <div className="d-flex flex-column align-items-center text-center mb-4">
                  <h2 className="text-white">
                    Fill this form and let’s discuss
                  </h2>
                  <p>
                    After you fill this form our team will get back to you
                    within 24 hours.
                  </p>
                </div>
              </div>

              <div className="col-lg-12 col-12 mx-auto">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="row  ">
                    <div className="col-md-6 p-3">
                      <label>Name</label>
                      <input
                        name="Name"
                        required
                        placeholder="Name"
                        type="text"
                        id="name"
                      />
                    </div>
                    <div className="col-md-6 p-3">
                      <label>Email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Email"
                        name="Email"
                        required
                      />
                    </div>
                    <div className="col-md-12  p-3">
                      <br></br>
                      <div className="phone-input-container">
                        <label>Phone Number</label>
                        <PhoneInput
                          defaultCountry="ae"
                          name="Phone"
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                        />
                      </div>
                      <br></br>
                    </div>
                    <div className="col-md-12">
                      <label>Message</label>
                      <textarea
                        name="Message"
                        id="message"
                        placeholder="Message"
                        required
                        rows="1"
                      />
                    </div>

                    <div className="col-md-12">
                      <div className="form-group submitBtn text-center">
                        <button
                          type="submit"
                          className="btn custom-btn mt-4 ms-auto me-4"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="main-footer">
        <div className="site-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12 text-center">
                <ul className="site-footer-links">
                  <li className="site-footer-link-item">
                    {/* <a href="/#home" className="site-footer-link">
                      Home
                    </a> */}
                    <HashLink smooth to="/#home" className="site-footer-link">
                      Home
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/#about" className="site-footer-link">
                      About
                    </a> */}
                    <HashLink smooth to="/#about" className="site-footer-link">
                      About
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/#roadmap" className="site-footer-link">
                      Roadmap
                    </a> */}
                    <HashLink
                      smooth
                      to="/#roadmap"
                      className="site-footer-link"
                    >
                      Roadmap
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/#utility" className="site-footer-link">
                      Utility
                    </a> */}
                    <HashLink
                      smooth
                      to="/#utility"
                      className="site-footer-link"
                    >
                      Utility
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/#buynft" className="site-footer-link">
                      Buy NFT
                    </a> */}
                    <HashLink smooth to="/#buynft" className="site-footer-link">
                      Buy NFT
                    </HashLink>
                  </li>

                  <li className="site-footer-link-item">
                    {/* <a href="/contact" className="site-footer-link">
                      Contact
                    </a> */}
                    <HashLink smooth to="/contact" className="site-footer-link">
                      Contact
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="site-footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-12 mt-1 text-center">
                  <p className="copyright-text">
                    Copyright © 2024 ICB Verse. All rights reserved
                  </p>
                </div>

                <div className="col-lg-12 col-12  justify-content-lg-center align-items-center">
                  <ul className="social-icon justify-content-lg-center align-items-center">
                    <li className="social-icon-item">
                      <a
                        href="https://t.me/icbnetwork_official"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon-link"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.75 0.25C12.0312 0.25 15.5 3.71875 15.5 8C15.5 12.2812 12.0312 15.75 7.75 15.75C3.46875 15.75 0 12.2812 0 8C0 3.71875 3.46875 0.25 7.75 0.25ZM11.5312 5.5625C11.6562 5.0625 11.3438 4.84375 11 4.96875L3.53125 7.84375C3.03125 8.03125 3.03125 8.34375 3.4375 8.46875L5.34375 9.0625L9.78125 6.25C10 6.125 10.1875 6.21875 10.0312 6.34375L6.4375 9.59375L6.3125 11.5625C6.5 11.5625 6.59375 11.4688 6.6875 11.375L7.625 10.4688L9.5625 11.9062C9.9375 12.0938 10.1875 12 10.2812 11.5625L11.5312 5.5625Z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </a>
                    </li>

                    <li className="social-icon-item">
                      <a
                        href="https://twitter.com/icbx_network"
                        className="social-icon-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M9.29401 6.928L14.357 1H13.157L8.76201 6.147L5.25001 1H1.20001L6.51001 8.784L1.20001 15H2.40001L7.04201 9.564L10.751 15H14.801L9.29401 6.928ZM7.65101 8.852L7.11301 8.077L2.83201 1.91H4.67501L8.12901 6.887L8.66701 7.662L13.158 14.132H11.315L7.65101 8.852Z"
                            fill="white"
                          ></path>
                        </svg>
                      </a>
                    </li>

                    <li className="social-icon-item">
                      <a
                        href="https://discord.com/invite/baGw2Kkz"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon-link"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M9.28125 7.625C9.28125 7.125 8.90625 6.71875 8.46875 6.71875C8 6.71875 7.625 7.125 7.625 7.625C7.625 8.09375 8 8.5 8.46875 8.5C8.90625 8.5 9.28125 8.09375 9.28125 7.625ZM5.53125 6.71875C5.09375 6.71875 4.71875 7.125 4.71875 7.625C4.71875 8.09375 5.09375 8.5 5.53125 8.5C6 8.5 6.34375 8.09375 6.34375 7.625C6.375 7.125 6 6.71875 5.53125 6.71875ZM14 1.65625C14 0.75 13.25 0 12.3438 0H1.625C0.71875 0 0 0.75 0 1.65625V12.4688C0 13.4062 0.71875 14.125 1.625 14.125H10.6875L10.2812 12.6562C12.625 14.8125 11.9688 14.25 14 16V1.65625ZM11.7188 9.25C11.7188 9.25 11.0312 10.4062 9.25 10.4688C9.25 10.4688 8.96875 10.125 8.71875 9.8125C9.78125 9.53125 10.1875 8.875 10.1875 8.875C9.84375 9.09375 9.53125 9.21875 9.25 9.34375C7.53125 10.0625 5.625 9.8125 4.25 9.0625C4.25 9.03125 4.0625 8.9375 3.9375 8.84375C3.9375 8.84375 4.3125 9.5 5.34375 9.8125C5.09375 10.0938 4.8125 10.4688 4.8125 10.4688C3.03125 10.4062 2.375 9.25 2.375 9.25C2.375 6.65625 3.5 4.59375 3.5 4.59375C4.65625 3.71875 5.75 3.75 5.75 3.75L5.84375 3.84375C4.40625 4.25 3.75 4.875 3.75 4.875C3.75 4.875 3.90625 4.78125 4.21875 4.65625C6.0625 3.84375 8.28125 3.84375 10.1875 4.875C10.1875 4.875 9.5625 4.28125 8.1875 3.875L8.3125 3.75C8.3125 3.75 9.40625 3.71875 10.5625 4.59375C10.5625 4.59375 11.7188 6.65625 11.7188 9.25Z"
                            fill="#F3F3F3"
                          ></path>
                        </svg>
                      </a>
                    </li>

                    <li className="social-icon-item">
                      <a
                        href="https://readicbnetwork.medium.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon-link"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_2)">
                            <path
                              d="M4.5 4.5L7.5 8.5L10.5 4.5M4.5 4.5H3M4.5 4.5V11M10.5 4.5H12M10.5 4.5V11M3 10.5H6M9 10.5H12M1.5 0.5H13.5C13.7652 0.5 14.0196 0.605357 14.2071 0.792893C14.3946 0.98043 14.5 1.23478 14.5 1.5V13.5C14.5 13.7652 14.3946 14.0196 14.2071 14.2071C14.0196 14.3946 13.7652 14.5 13.5 14.5H1.5C1.23478 14.5 0.98043 14.3946 0.792893 14.2071C0.605357 14.0196 0.5 13.7652 0.5 13.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5Z"
                              stroke="white"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1_2">
                              <rect width="15" height="15" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Contact;
